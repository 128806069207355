:root {
  --dark100: #121212;
  --dark150: #171717;
  --dark200: #212121;
  --dark250: #252525;
  --dark300: #303030;
  --text-color: #eee;
  --border-color: #eeeeee9c;
  --monospace: monospace;
  --small-text: .5rem;
  --raw-day-color: 144, 41, 241;
  --day-color: rgb(var(--raw-day-color));
  --easing-func: cubic-bezier(.22, 1, .36, 1);
}

body {
  background-color: var(--dark100);
  color: var(--text-color);
}

main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#req-form {
  grid-template-columns: auto auto;
  gap: 1rem 2rem;
  display: grid;
}

#req-form input[type="submit"] {
  grid-column: span 2;
}

#main-view {
  transition: opacity .5s, transform .15s var(--easing-func), box-shadow .25s;
  background-color: var(--dark150);
  z-index: 4;
  border-radius: 5px;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr auto;
  margin: 10px;
  padding: 2rem;
  display: grid;
  transform: scale(1);
  box-shadow: 0 0 #0000;
}

#main-view:hover {
  transform: scale(1.4)translateY(25%);
  box-shadow: 0 15px 50px 8px #000b;
}

#hours {
  font-family: var(--monospace);
  font-size: max(.5rem, 10px);
}

.hour:not(:last-child) {
  border-bottom: var(--border-color) solid 1px;
}

#months {
  grid-template-rows: auto auto;
  grid-template-columns: var(--cols);
  grid-auto-flow: column;
  display: grid;
}

.month-name, #months .year-name {
  font-family: var(--monospace);
  font-size: var(--small-text);
  justify-content: center;
  align-items: center;
  display: flex;
}

#months :not(:first-child, .month-name + .year-name) {
  border-left: var(--border-color) solid 1px;
}

#months .year-name {
  grid-column: var(--month-span, 1);
  grid-row: 2;
  padding-top: .3rem;
}

#day-view {
  grid-template-columns: repeat(12, 1fr);
  gap: 10px 2px;
  margin-top: 5rem;
  display: grid;
}

#day-view .year-view {
  grid-template-columns: repeat(12, 1fr);
  display: grid;
}

#day-view .filler {
  grid-column: span var(--span, 0);
}

#day-view .year-name {
  grid-column: span 12;
  margin-left: 3px;
  font-weight: bold;
}

#day-view .month {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.month-visual {
  grid-template-rows: repeat(7, 10px);
  grid-auto-flow: column;
  gap: 3px;
  display: grid;
}

.month-visual .day {
  background-color: rgba(var(--raw-day-color), var(--intensity, 0));
  transition: transform .15s var(--easing-func);
  z-index: 1;
  width: 10px;
  height: 10px;
  transform: scale(1);
}

.month-visual .day:hover {
  z-index: 2;
  transform: scale(2);
}

.month-visual .day:after {
  content: var(--date, "{no date?!}");
  background: var(--dark200);
  border-radius: 4px;
  width: max-content;
  height: max-content;
  padding: .1rem .2rem;
  font-size: small;
  display: none;
  position: relative;
  top: 100%;
}

.month-visual .day:hover:after {
  display: block;
}

.month-visual .day.start-day-0 {
  grid-row: 1;
}

.month-visual .day.start-day-1 {
  grid-row: 2;
}

.month-visual .day.start-day-2 {
  grid-row: 3;
}

.month-visual .day.start-day-3 {
  grid-row: 4;
}

.month-visual .day.start-day-4 {
  grid-row: 5;
}

.month-visual .day.start-day-5 {
  grid-row: 6;
}

.month-visual .day.start-day-6 {
  grid-row: 7;
}

.hidden {
  opacity: 0;
}

input[type="text"] {
  background-color: var(--dark200);
  color: var(--text-color);
  border: 1px solid #ffffff5e;
  border-radius: 4px;
  padding: 5px 10px;
}

input[type="text"]:hover {
  background-color: var(--dark300);
}

input[type="button"], input[type="submit"] {
  background-color: var(--day-color);
  color: var(--text-color);
  cursor: pointer;
  border: 1px solid #ffffff5e;
  border-radius: 4px;
  margin: 2px 5px;
  padding: 6px 20px;
}

canvas {
  image-rendering: optimizespeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
}

footer {
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
  display: flex;
}

.credits {
  text-align: center;
  font-size: .75rem;
}

footer a {
  color: var(--day-color);
  text-decoration: none;
}

.github-icon {
  width: 3rem;
}

.github-link {
  color: var(--text-color);
  transition: color .25s;
}

.github-link:is(:hover, :focus, :focus-visible) {
  color: var(--day-color);
}

/*# sourceMappingURL=index.d3d891cb.css.map */
